import React from 'react'
// import { Routes, Route } from "react-router-dom"
// import { Link } from "react-router-dom"

// import { apiSlice } from "../../../../api/apiSlice"

// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'

// import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
// import Table from 'react-bootstrap/Table'
// import ListGroup from 'react-bootstrap/ListGroup'
import Chart from "react-apexcharts"

import { DashboardModule } from '../../../../../shared/DashboardModule/DashboardModule'

export function RevenueChartModule() {

  // Docs: https://apexcharts.com/docs/react-charts/

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
  })

  const chart_data = {

    // options doc: https://apexcharts.com/docs/options/annotations/
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeinout'
        },
        id: "revenue-chart-dashboard-module"
      },
      xaxis: {
        categories: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022]
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return `${USDollar.format(value)}`
          }
        },
      },
      markers: {
        size: [4, 7]
      }
    },

    series: [
      {
        name: "Revenue",
        data: [300000, 400000, 450000, 500000, 490000, 600000, 700000, 910000]
      }
    ]

  }

  return (
    <DashboardModule>
      <Card.Header>
        <i className="bi bi-currency-dollar me-1"></i>Firm Revenue
      </Card.Header>
      <div>
        <Chart
          options={chart_data.options}
          series={chart_data.series}
          type="line"
          width="100%"
          height="250"
        />
      </div>
    </DashboardModule>
  )
}