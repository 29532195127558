import React from 'react'
// import {
// // Link,
//   Redirect
// } from 'react-router-dom'

import DashboardNavbar from './DashboardNavbar/DashboardNavbar'
import DashboardSubNavbar from './DashboardSubNavbar/DashboardSubNavbar'
import DashboardBreadcrumb from './DashboardBreadcrumb/DashboardBreadcrumb'
import DashboardBody from './DashboardBody/DashboardBody'

const Dashboard = () => (
  <div id="dashboard-container" style={{ backgroundColor: "#eeeeee", "paddingBottom": "100px", "minHeight": "500px" }}>
    <DashboardNavbar />
    <DashboardSubNavbar />
    <DashboardBreadcrumb />
    <DashboardBody />
  </div>
)

export default Dashboard