import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://dummy5.lobsterlaw.com/' }),
  tagTypes: ['DUMMY', 'MATTER', 'CLIENT', 'AI_DATA', 'ME'],
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => '/me',
      providesTags: ['ME'],
    }),
    getDummy: builder.query({
      query: () => '/dummy_data',
      providesTags: ['DUMMY'],
    }),
    getMatters: builder.query({
      query: () => '/v1/matters',
      providesTags: ['MATTER'],
    }),
    getClients: builder.query({
      // query: () => '/badclients',
      query: () => '/clients',
      // providesTags: ['CLIENT'],
      providesTags: (result, error, id) => [{ type: 'CLIENT', id }],
      transformResponse: (response, meta, arg) => {
        // return response
        if (!response["_metadata"].success_flag) {
          throw Error(response["_metadata"].error_message)
        }
        return response.data
        // return ["ABC", "DEF"]
        // throw Error('BOOM')
      },
    }),
    getAIData: builder.query({
      query: (new_ai_query) => ({
        url: '/ai_data',
        // url: '/fake/500',
        // url: '/fake_ai_data',
        method: 'POST',
        body: new_ai_query,
      }),
      providesTags: ['AI_DATA'],
    }),
    // getMatter: builder.query({
    //   query: (matter_id) => `/matters/${matter_id}`,
    //   providesTags: ['MATTER'],
    // }),
    // getPost: builder.query({
    //   query: (postId) => `/posts/${postId}`,
    // }),
    // addNewPost: builder.mutation({
    //   query: (initialPost) => ({
    //     url: '/posts',
    //     method: 'POST',
    //     body: initialPost,
    //   }),
    //   invalidatesTags: ['Post'],
    // }),
    // addNewDummy: builder.mutation({
    //   query: (new_dummy_data) => ({
    //     url: '/dummy_data',
    //     method: 'POST',
    //     body: new_dummy_data,
    //   }),
    //   invalidatesTags: ['DUMMY'],
    // }),
  }),
})

export const {
  useGetMeQuery,
  useGetDummyQuery,
  useGetMattersQuery,
  useGetClientsQuery,
  useLazyGetAIDataQuery,
  // useGetPostsQuery,
  // useGetPostQuery,
  // useAddNewPostMutation,
  useAddNewDummyMutation,
} = apiSlice
