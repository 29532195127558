import { configureStore } from '@reduxjs/toolkit'

// import counterReducer from '../features/counter/counterSlice'
import userProfileReducer from '../slices/userProfileSlice'


// import postsReducer from '../features/posts/postsSlice'
// import usersReducer from '../features/users/usersSlice'
// import notificationsReducer from '../features/notifications/notificationsSlice'
import { apiSlice } from '../slices/apiSlice'

export default configureStore({
  reducer: {
    // posts: postsReducer,
    // users: usersReducer,
    // notifications: notificationsReducer,
    // counter: counterReducer,
    userProfile: userProfileReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
})
