import React from 'react'
import {
  // Container,
  Form,
  Button,
  // Row,
  // Col
  // InputGroup,
  // FormControl
} from "react-bootstrap"
import { useForm } from "react-hook-form"
// import { LinkContainer } from 'react-router-bootstrap'
// import { Link } from "react-router-dom"


// import { useParams } from 'react-router-dom'
// import Table from 'react-bootstrap/Table'

import {
  // Card
} from 'react-bootstrap'

import ContactWorkflowFormItem from './ContactWorkflowFormItem/ContactWorkflowFormItem'

const generateContactWorkflowFormItems = (workflow_steps, register, errors, onRemoveContactWorkflowStep) => {
  return workflow_steps.map((workflow_step, index) => {
    // console.log("index", index)
    return (
      <ContactWorkflowFormItem
        key={index}
        workflow_step_number={index}
        form_errors={errors}
        register={register}
        onRemoveContactWorkflowStep={onRemoveContactWorkflowStep}
      />
      // <ContactWorkflowFormItem workflow_step_number={2} form_errors={errors} register={register} />
    )
  })
}

const ContactWorkflowForm = (props) => {
  let {
    handleSaveContactWorkflow,
    handleAddContactWorkflowStep,
    handleRemoveContactWorkflowStep,
    handleCancel,
    initial_contact_workflow
  } = props

  // return (
  //   <div>
  //     {generateContactWorkflowFormItems(initial_contact_workflow.workflow_steps)}
  //     <Row>
  //       <Col className="text-end">
  //         <Button variant="light">
  //           Cancel
  //         </Button>
  //         <Button variant="primary" type="submit" className="ms-3">
  //           Save
  //         </Button>
  //       </Col>
  //     </Row>
  //     <pre
  //       style={{
  //         backgroundColor: '#eee',
  //         padding: '1em',
  //         marginTop: '1em',
  //       }}
  //     >
  //       <code>
  //         initial_contact_workflow=
  //         {JSON.stringify(initial_contact_workflow, null, 4)}
  //       </code>
  //     </pre>
  //   </div>
  // )

  // Convert initial_contact_workflow.workflow_steps to initState


  const initState = {
    // recipient_email_address: recipient_email_address,
    // recipient_name: recipient_name,
    // email_subject: "yyy",
    // email_body: "zzz"
    // contact_workflow_step_1: "THIS IS STEP 1",
    // contact_workflow_step_2: "THIS IS STEP 2",
    // contact_workflow_step_3: "THIS IS STEP 3",
  }

  for(let i=0; i < initial_contact_workflow.workflow_steps.length; i++){
    initState[`contact_workflow_step_${i}`] = initial_contact_workflow.workflow_steps[i].step_description
  }


  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = React.useState(initState)


  const createNewWorkflowObject = (values) => {
    const new_contact_workflow_object = {
      workflow_steps: []
    }

    for (const [key, value] of Object.entries(values)) {
      if (key.includes("contact_workflow_step_")) {
        new_contact_workflow_object.workflow_steps.push({
          step_description: value
        })
      }
    }
    return new_contact_workflow_object
  }

  const onSubmit = (values) => {
    // console.log("onSubmit HIT", values)
    // console.log("Values:::", JSON.stringify(values))

    // Convert values to initial_contact_workflow object
    // const new_contact_workflow_object = {
    //   workflow_steps: []
    // }

    // for (const [key, value] of Object.entries(values)) {
    //   if (key.includes("contact_workflow_step_")) {
    //     new_contact_workflow_object.workflow_steps.push({
    //       step_description: value
    //     })
    //   }
    // }
    // console.log(JSON.stringify(new_contact_workflow_object, null, 4))
    const new_contact_workflow_object = createNewWorkflowObject(values)

    if (values.submit_type === "add_another_step") {
      handleAddContactWorkflowStep(new_contact_workflow_object)
    } else if (values.submit_type === "remove_step") {
      console.log("remove step here")
      handleRemoveContactWorkflowStep(new_contact_workflow_object, values.step_to_remove)
    } else {
      handleSaveContactWorkflow(new_contact_workflow_object)
    }
  }

  // const onError = (error) => {
  //   console.log("ERROR:::", error)
  // }

  const {
    register,
    handleSubmit,
    getValues,
    // watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues
  })

  const onRemoveContactWorkflowStep = (workflow_step_number) => {
    // console.log("Handling onRemoveContactWorkflowStep")
    const new_contact_workflow_object = createNewWorkflowObject(getValues())
    handleRemoveContactWorkflowStep(new_contact_workflow_object, workflow_step_number)
  }

  // const x = JSON.stringify(data);
  // const y = JSON.stringify(listShow);

  // React.useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     console.log(">>", value, name, type)
  //     // {1: '1', 2: '9'} '2' 'change'
  //   })

  //   return () => subscription.unsubscribe()
  // }, [watch])

  // const current_email_subject_length = watch('email_subject').length
  // const current_email_body_length = watch('email_body').length

  return (
    <div className="my-1">

      {/* <Form onSubmit={handleSubmit(onSubmit, onError)}> */}
      <Form onSubmit={(e) => { e.preventDefault() }}>

        {generateContactWorkflowFormItems(initial_contact_workflow.workflow_steps, register, errors, onRemoveContactWorkflowStep)}

        <div className="row mb-5">
          <div className="col text-end">
            <Button
              variant="success"
              className="xms-2 btn-sm"
              onClick={handleSubmit((data) =>
                onSubmit({
                  ...data,
                  "submit_type": "add_another_step"
                })
              )}
            >
              <i className="bi bi-plus-lg me-1"></i>Add Another Step
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Button
              variant="primary"
              // type="submit"
              onClick={handleSubmit((data) =>
                onSubmit({
                  ...data,
                  "submit_type": "save_workflow"
                })
              )}
            >
              Save Workflow
            </Button>
          </div>
          <div className="col text-end">
            <Button
              variant="light"
              className="ms-2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>

        {/* <pre
          style={{
            backgroundColor: '#eee',
            padding: '1em',
            marginTop: '1em',
          }}
        >
          <code>
          initial_contact_workflow=
            {JSON.stringify(initial_contact_workflow, null, 4)}
          </code>
        </pre> */}


      </Form>
    </div>
  )
}

export default ContactWorkflowForm