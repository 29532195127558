import React, { useEffect } from 'react'
// import { LinkContainer } from 'react-router-bootstrap'

// import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const DashboardHomeBreadcrumb = (props) => {
  let { organization_name } = props

  useEffect(() => {
    document.title = `LobsterLaw - ${organization_name}`
  })

  return (
    // <Container fluid>
    <Breadcrumb className="">
      <Breadcrumb.Item active>{organization_name}</Breadcrumb.Item>
    </Breadcrumb>
    // </Container>
  )
}

export default DashboardHomeBreadcrumb