// import React from 'react'
import React, { useState } from 'react'

import mailtoLink from 'mailto-link'

import {
  // Button,
  Modal
} from 'react-bootstrap'
import EmailForm from "./EmailForm/EmailForm"
import EmailAIForm from "./EmailAIForm/EmailAIForm"

function EmailModal(props) {

  let {
    recipient_email_address,
    recipient_name
  } = props
  // const recipient_email_address = "matt+fakeaddress@joinparadigm.com"
  const generateSendEmailButtonContent = function (text) {
    return (<span><i className="bi bi-send me-1"></i>{text}</span>)
  }

  const [email_send_button_text, setSendButtonText] = useState(generateSendEmailButtonContent("Send Email"))
  const [email_body, setEmailBody] = useState(false)
  const [email_subject, setEmailSubject] = useState(false)
  const [should_show_modal, setShouldShowModal] = useState(false)
  const [show_email_form, setShowEmailForm] = useState(true)
  const [show_email_ai_form, setShowEmailAIForm] = useState(false)


  const handleCloseModal = () => {
    console.log('handleCloseModal')
    setShouldShowModal(false)
    setShowEmailForm(true)
    setShowEmailAIForm(false)
    setSendButtonText(generateSendEmailButtonContent("Send Email"))
  }

  const handleEmailFormCancel = () => {
    console.log('handleEmailFormCancel')
    handleCloseModal()
  }

  const handleShowModal = () => {
    console.log('handleShowModal')
    setEmailBody(false)
    setEmailSubject(false)
    setShowEmailForm(true)
    setShowEmailAIForm(false)
    setShouldShowModal(true)
  }

  const handleSendEmail = (data) => {
    console.log('handleSendEmail', data)
    // handleCloseModal()
    window.open(mailtoLink({ to: 'matt+fakeaddress@joinparadigm.com', subject: data.email_subject, body: data.email_body }))
    setSendButtonText(generateSendEmailButtonContent("Send Email Again"))
  }

  const handleShowAIForm = () => {
    console.log('handleShowAIForm')
    setShowEmailForm(false)
    setShowEmailAIForm(true)
  }

  const handleAIFormSubmit = ({ new_ai_generated_email_body, new_ai_generated_email_subject }) => {
    console.log('handleAIFormSubmit')
    setEmailBody(new_ai_generated_email_body)
    setEmailSubject(new_ai_generated_email_subject)
    setShowEmailAIForm(false)
    setShowEmailForm(true)
  }

  const handleAIFormCancel = () => {
    console.log('handleAIFormCancel')
    setShowEmailAIForm(false)
    setShowEmailForm(true)
  }

  // const handleSectionToggle = () => {
  //   // console.log('handleSectionToggle')
  //   setShowEmailForm(!show_email_form)
  //   setShowEmailAIForm(!show_email_ai_form)
  // }

  return (
    <>

      <a href="#" onClick={handleShowModal}>{recipient_email_address}</a>

      {/* <Button variant="primary" onClick={handleShowModal}>
        Launch demo modal
      </Button> */}

      <Modal show={should_show_modal} onHide={handleCloseModal}>

        <Modal.Header>
          <Modal.Title as="div" className="fw-bold">Email Contact</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {/* Sending email to:{' '} */}

          {/* {recipient_email_address} */}

          {show_email_form && (
            <EmailForm
              recipient_name={recipient_name}
              recipient_email_address={recipient_email_address}
              email_body={email_body}
              email_subject={email_subject}
              handleShowAIForm={handleShowAIForm}
              handleSendEmail={handleSendEmail}
              handleEmailFormCancel={handleEmailFormCancel}
              email_send_button_text={email_send_button_text}
            />
          )}
          {show_email_ai_form && (
            <EmailAIForm
              recipient_name={recipient_name}
              handleAIFormSubmit={handleAIFormSubmit}
              handleAIFormCancel={handleAIFormCancel}
            />
          )}

          {/* <Button variant="primary" onClick={handleSectionToggle}>
              Toggle Section
          </Button> */}

        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendEmail}>
            Submit
          </Button>
        </Modal.Footer> */}

      </Modal>
    </>
  )
}

export default EmailModal