import React from 'react'
import Container from 'react-bootstrap/Container'
// import { LinkContainer } from 'react-router-bootstrap'

// import {
//   Navigate
// } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import {
  Card
} from 'react-bootstrap'

import Table from 'react-bootstrap/Table'
import Placeholder from 'react-bootstrap/Placeholder'

import { useGetMattersQuery } from '../../../../../slices/apiSlice.js'

const renderFakeTableRows = () => {
  return(
    <tbody>
      <tr>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
      </tr>
      <tr>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
      </tr>
      <tr>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
      </tr>
    </tbody>
  )
}

const renderTableRows = (matters) => {
  return (
    <tbody>
      {matters.map((matter) => (
        <tr key={matter.id}>
          <td>{matter.id}</td>
          <td>{matter.client}</td>
          <td>{matter.case}</td>
        </tr>
      ))}
    </tbody>
  )
}

const Matters = () => {
  // let matters = []
  const {
    data: matters_data = [],
    // isLoading,
    // isFetching,
    // isSuccess,
    isError,
    error,
  } = useGetMattersQuery("ABC")

  let table_rows = renderFakeTableRows()
  let error_markup = null

  if (matters_data.data) {
    table_rows = renderTableRows(matters_data.data)
    // console.log("matters_data: ", matters_data.data)
    // matters = matters_data.data
  } else if (isError) {
    table_rows = null
    // console.log("error", error)
    error_markup = (
      <div className="alert alert-danger" role="alert">
        Error: {error.status}
      </div>
    )
  } else {
  }

  // const matters = [
  //   { id: 1, client: 'Peter Venkman', case: 'Ghost Containment Breach' },
  //   { id: 2, client: 'Ray Stantz', case: 'Ectoplasmic Residue Damage' },
  //   { id: 3, client: 'Egon Spengler', case: 'Proton Pack Patent Dispute' },
  //   { id: 4, client: 'Winston Zeddemore', case: 'Spectral Harassment' },
  //   { id: 5, client: 'Dana Barrett', case: 'Zuul Infestation' },
  // ]

  return (
    <div id="matters-container" className="">
      <Container>
        {error_markup}
        <Card className="shadow-sm">
          <Card.Header>
            Matters
          </Card.Header>
          <div className="table-responsive-sm">
            <Table borderless hover style={{ marginLeft: "8px" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Client</th>
                  <th>Case</th>
                </tr>
              </thead>
              {table_rows}
            </Table>
          </div>
        </Card>
      </Container>
    </div>
  )
}

export default Matters