import React from 'react'
import Container from 'react-bootstrap/Container'
// import { LinkContainer } from 'react-router-bootstrap'

// import {
//   Navigate
// } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import {
  Card
} from 'react-bootstrap'

import Table from 'react-bootstrap/Table'
import Placeholder from 'react-bootstrap/Placeholder'
import Badge from 'react-bootstrap/Badge'


// import { useGetMattersQuery } from '../../../../../slices/apiSlice.js'
import { useGetPaymentsQuery } from '../../../../../slices/apiSlice.js'
import {
  timestampToFormattedDateString,
  amountInCentsToFormattedDollarString
} from '../../../../../lib/helpers.js'

const renderFakeTableRows = () => {
  return(
    <tbody>
      <tr>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
      </tr>
      <tr>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
      </tr>
      <tr>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
        <Placeholder animation="glow" as="td">
          <Placeholder style={{ width: '30px' }} size="lg" />{" "}
          <Placeholder style={{ width: '70px' }} size="lg" />{" "}
          <Placeholder style={{ width: '100px' }} size="lg" />
        </Placeholder>
      </tr>
    </tbody>
  )
}

const renderTableRows = (payments) => {
  //     { payment_id: 1,  client_id: 1,  client: 'Peter Venkman PHD',         matter_id: 1,  matter: 'Ghost Containment Breach',        amount_in_cents: 385700, payment_create_ts: 1672444800 },

  return (
    <tbody>
      {payments.map((payment) => (
        <tr key={payment.payment_id}>
          <td className="ps-3" style={{ whiteSpace: "nowrap" }}>{payment.payment_id}</td>
          <td style={{ whiteSpace: "nowrap" }}>{payment.client}</td>
          <td style={{ whiteSpace: "nowrap" }}>{payment.matter}</td>
          <td style={{ whiteSpace: "nowrap" }}>
            <Badge pill bg="success">
              Successful
            </Badge>
          </td>
          <td className="text-end" style={{ whiteSpace: "nowrap" }}>{amountInCentsToFormattedDollarString(payment.amount_in_cents)}</td>
          <td className="text-end pe-4" style={{ whiteSpace: "nowrap" }}>{timestampToFormattedDateString(payment.payment_create_ts)}</td>
        </tr>
      ))}
    </tbody>
  )
}

const Payments = () => {
  // let payments = []
  const {
    data: payments_data = [],
    // isLoading,
    // isFetching,
    // isSuccess,
    isError,
    error,
  } = useGetPaymentsQuery("ABC")

  let table_rows = renderFakeTableRows()
  let error_markup = null

  if (payments_data.data) {
    table_rows = renderTableRows(payments_data.data)
    // console.log("payments_data: ", payments_data.data)
    // payments = payments_data.data
  } else if (isError) {
    table_rows = null
    // console.log("error", error)
    error_markup = (
      <div className="alert alert-danger" role="alert">
        Error: {error.status}
      </div>
    )
  } else {
  }

  // const payments = [
  //   { id: 1, client: 'Peter Venkman', case: 'Ghost Containment Breach' },
  //   { id: 2, client: 'Ray Stantz', case: 'Ectoplasmic Residue Damage' },
  //   { id: 3, client: 'Egon Spengler', case: 'Proton Pack Patent Dispute' },
  //   { id: 4, client: 'Winston Zeddemore', case: 'Spectral Harassment' },
  //   { id: 5, client: 'Dana Barrett', case: 'Zuul Infestation' },
  // ]

  return (
    <div id="payments-container" className="">
      <Container>
        {error_markup}
        <Card className="shadow-sm">
          <Card.Header>
            Payments
          </Card.Header>
          <div className="table-responsive-lg">
            <Table borderless hover style={{ xpaddingLeft: "8px", xpaddingRight: "558px" }}>
              <thead>
                <tr>
                  <th className="ps-3">#</th>
                  <th>Client</th>
                  <th>Matter</th>
                  <th>Status</th>
                  <th className="text-end">Amount</th>
                  <th className="text-end pe-4" style={{ xpaddingRight: "558px" }}>Created</th>
                </tr>
              </thead>
              {table_rows}
            </Table>
          </div>
        </Card>
      </Container>
    </div>
  )
}

export default Payments