import React from 'react'
// import React, { useState } from 'react'
import {
  // Container,
  // Form,
  Button,
  // Row,
  // Col
  // InputGroup,
  // FormControl,
  Spinner
} from "react-bootstrap"

export function FancyLoadingButton(props) {

  let {
    buttonisloading,
    buttontext,
    buttonloadingtext
  } = props

  let button_is_disabled = buttonisloading

  const buttonloadingtext_string = buttonloadingtext || buttontext

  return (
    <Button { ...props } disabled={button_is_disabled}>
      { buttonisloading ?
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="me-2"
        /> : "" }
      { buttonisloading ?  buttonloadingtext_string : buttontext}
    </Button>
  )

}
