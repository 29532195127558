import React from 'react'
// import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom"

// import {
//   createSelector
// } from '@reduxjs/toolkit'

// import {
// // Link,
//   Redirect
// } from 'react-router-dom'

// import DashboardNavbar from './DashboardNavbar/DashboardNavbar'
// import DashboardBody from './DashboardBody/DashboardBody'
import ContactDetail from './ContactDetail/ContactDetail'
import ContactsList from './ContactsList/ContactsList'
import ContactWorkflows from './ContactWorkflows/ContactWorkflows'


const Contacts = () => {

  // const selectDummyResult = apiSlice.endpoints.getDummy.select()
  // // const emptyDummy = []
  // const selectAllDummy = createSelector(
  //   selectDummyResult,
  //   usersResult => usersResult.data
  // )

  // console.log("selectAllDummy", selectAllDummy())
  // console.log("selectDummyResult", selectDummyResult)

  return (
    <div id="contacts-container" className="">
      {/* <h1>Dashboard Body</h1> */}
      <Routes>
        <Route path="/" element={<ContactsList />} />
        <Route path="contact_workflows/*" element={<ContactWorkflows />} />
        <Route path=":contact_id/*" element={<ContactDetail />} />
        {/* <Route path="contacts/*" element={<Contacts />} /> */}
        {/* <Route path="*" element={<NoMatch />} /> */}
      </Routes>
    </div>
  )
}

export default Contacts