// import React, { useState } from 'react'
import React, { useEffect } from 'react'
// import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { useGetMeQuery } from "../../slices/apiSliceDummy"

import { useSelector, useDispatch } from 'react-redux'
import { updateUserProfile } from '../../slices/userProfileSlice'

import Homepage from './Homepage/Homepage'
import Dashboard from './Dashboard/Dashboard'
import Footer from './Footer/Footer'

import './Root.scss'

// https://icons.getbootstrap.com/
import "bootstrap-icons/font/bootstrap-icons.css"

function NoMatch() {

  useEffect(() => {
    document.title = "LobsterLaw - 404"
  })

  return (
    <div style={{ textAlign: 'center', paddingTop: '100px' }}>
      <h2>404</h2>
      <p>
        <a href="/">Go to Dashboard</a>
      </p>
    </div>
  )
}

const Root = () => {

  // TODO: GET auth token from local storage here???
  // const auth_token = "FAKE_AUTH_TOKEN"

  const {
    data: get_me_query_data = [],
    isLoading,
    // isFetching,
    isSuccess,
    isError,
    error,
  } = useGetMeQuery()

  const state_user_id = useSelector(state => state.userProfile.user_id)
  const dispatch = useDispatch()

  // console.log("state_user_id:", state_user_id)

  useEffect(() => {
    // console.log("Checking if state_user_id is null and isSuccess is true")
    if (state_user_id === null && isSuccess) {
      // console.log("Dispatching updateUserProfile", get_me_query_data)
      dispatch(updateUserProfile({
        user_id: get_me_query_data.data.user_id,
        username: get_me_query_data.data.username,
        email_address: get_me_query_data.data.email_address,
        organizations: get_me_query_data.data.organizations,
      }))
    }
  })

  // console.log("ME DATA", get_me_query_data)

  if (isLoading || state_user_id === null) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '100px' }}>
        <p>Authenticating...</p>
      </div>
    )
  } else if (isError) {
    return (
      <div>{error.toString()}</div>
    )
  }

  // const org_id = get_me_query_data.context.awsRequestId
  // const user_id = get_me_query_data.data.user_id
  // const organizations = get_me_query_data.data.organizations
  // const org_id = organizations[0].organization_id

  return (
    <BrowserRouter>
      <div className="root">
        <nav className="root__nav">
          {/* <Link to="/">Homepage</Link> */}
          {/* <Link to="/o/xxx">Dashboard</Link> */}
          {/* <Link to="/login">Login</Link>
          <Link to="/logout">Logout</Link>
          <Link to="/dashboard">Dashboard</Link> */}
        </nav>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/o/:organization_id/*" element={<Dashboard />} />
          {/* <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/logout" element={<Logout setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/dashboard" element={<Dashboard isAuthenticated={isAuthenticated} />} /> */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default Root