import React from 'react'
import Container from 'react-bootstrap/Container'
// import { LinkContainer } from 'react-router-bootstrap'
// import { LinkContainer } from 'react-router-bootstrap'
import { Link } from "react-router-dom"

// import {
//   Navigate
// } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import {
  Card
} from 'react-bootstrap'

import Table from 'react-bootstrap/Table'

const ContactsList = () => {

  const contacts = [
    { id: 1, name: 'Peter Venkman', phone: '555-1234', email: 'p.venkman@ghostbusters.com' },
    { id: 2, name: 'Ray Stantz', phone: '555-2345', email: 'r.stantz@ghostbusters.com' },
    { id: 3, name: 'Egon Spengler', phone: '555-3456', email: 'e.spengler@ghostbusters.com' },
    { id: 4, name: 'Winston Zeddemore', phone: '555-4567', email: 'w.zeddemore@ghostbusters.com' },
    { id: 5, name: 'Dana Barrett', phone: '555-5678', email: 'd.barrett@zuul.com' },
    { id: 6, name: 'Louis Tully', phone: '555-6789', email: 'l.tully@ghostbusters.com' },
    { id: 7, name: 'Janine Melnitz', phone: '555-7890', email: 'j.melnitz@ghostbusters.com' },
    { id: 8, name: 'Slimer', phone: '555-8901', email: 'slimer@ghostbusters.com' },
    { id: 9, name: 'Walter Peck', phone: '555-9012', email: 'w.peck@epa.gov' },
    { id: 10, name: 'Gozer', phone: '555-0123', email: 'gozer@zuul.com' },
    { id: 11, name: 'Vinz Clortho', phone: '555-1235', email: 'v.clortho@zuul.com' },
    { id: 12, name: 'Zuul', phone: '555-2346', email: 'zuul@zuul.com' },
    { id: 13, name: 'Stay Puft Marshmallow Man', phone: '555-3457', email: 'staypuft@marshmallow.com' },
    { id: 14, name: 'Librarian Ghost', phone: '555-4568', email: 'librarian@ghostbusters.com' },
    { id: 15, name: 'Vigo the Carpathian', phone: '555-5679', email: 'vigo@carpathia.com' },
    { id: 16, name: 'Mayor Lenny', phone: '555-6780', email: 'mayor.lenny@nyc.gov' },
    { id: 17, name: 'Dr. Janosz Poha', phone: '555-7891', email: 'j.poha@manhattanmuseum.com' },
  ]

  return (
    <Container>
      <Card className="shadow-sm">
        <Card.Header>
            Contacts
        </Card.Header>
        <div className="table-responsive-sm">
          <Table borderless hover style={{ marginLeft: "8px" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>
                    {contact.id}
                  </td>
                  <td><Link to={`./${contact.id}`}>{contact.name}</Link></td>
                  <td>{contact.phone}</td>
                  <td>{contact.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </Container>

  )
}

export default ContactsList