const timestampToFormattedDateString = function(timestamp) {
  if (timestamp < 16724448000) {
    timestamp = timestamp * 1000
  }
  var date = new Date(timestamp)
  return date.toLocaleDateString()
}

const amountInCentsToFormattedDollarString = function(amount_in_cents) {
  return (amount_in_cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
}

export {
  timestampToFormattedDateString,
  amountInCentsToFormattedDollarString,
}