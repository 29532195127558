// import React from 'react'
import React, {
  // useState
} from 'react'

import {
  Col,
  Card
} from 'react-bootstrap'

export function DashboardModule(props) {

  return (
    <Col>
      <Card className="h-100 shadow-sm">
        { /* adds children passed */
          props.children
        }
      </Card>
    </Col>
  )
}