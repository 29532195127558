

import React from 'react'

const Footer = () => {
  return (
    <div className="container-fluid" style={{ backgroundColor: "white" }}>
      <footer className="py-0 text-black-50">
        <div className="text-center text-body-secondary xborder-top pt-4" style={{ paddingBottom: "50px" }}>
          <div><small>&copy; 2023 LobsterLaw, Inc</small></div>
          <a href="/"><img src="https://www.lobsterlaw.com/images/logo1.png" alt="Logo" width="27" height="27" className="mt-2" /></a>
          <div style={{ fontSize: "70%" }} className="">
            <div className="d-block d-sm-none">
              XS
            </div>
            <div className="d-none d-sm-block d-md-none">
              SM
            </div>
            <div className="d-none d-md-block d-lg-none">
              MD
            </div>
            <div className="d-none d-lg-block d-xl-none">
              LG
            </div>
            <div className="d-none d-xl-block d-xxl-none">
              XL
            </div>
            <div className="d-none d-xxl-block">
              XXL
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer