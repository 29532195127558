import React from 'react'
// import React, { useState } from 'react'

import Container from 'react-bootstrap/Container'
import {
  Card
} from 'react-bootstrap'

// import { LinkContainer } from 'react-router-bootstrap'

// import {
//   Navigate
// } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
// import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
// import Form from 'react-bootstrap/Form'
import EmailModal from '../../../../../shared/EmailModal/EmailModal'

const Contacts = () => {

  let { contact_id } = useParams()

  // const handleEmailClick = () => {
  //   console.log('handleEmailClick')
  //   // setShow(false)
  // }

  const contacts = [
    { id: 1, name: 'Peter Venkman', phone: '555-1234', email: 'p.venkman@ghostbusters.com' },
    { id: 2, name: 'Ray Stantz', phone: '555-2345', email: 'r.stantz@ghostbusters.com' },
    { id: 3, name: 'Egon Spengler', phone: '555-3456', email: 'e.spengler@ghostbusters.com' },
    { id: 4, name: 'Winston Zeddemore', phone: '555-4567', email: 'w.zeddemore@ghostbusters.com' },
    { id: 5, name: 'Dana Barrett', phone: '555-5678', email: 'd.barrett@zuul.com' },
    { id: 6, name: 'Louis Tully', phone: '555-6789', email: 'l.tully@ghostbusters.com' },
    { id: 7, name: 'Janine Melnitz', phone: '555-7890', email: 'j.melnitz@ghostbusters.com' },
    { id: 8, name: 'Slimer', phone: '555-8901', email: 'slimer@ghostbusters.com' },
    { id: 9, name: 'Walter Peck', phone: '555-9012', email: 'w.peck@epa.gov' },
    { id: 10, name: 'Gozer', phone: '555-0123', email: 'gozer@zuul.com' },
    { id: 11, name: 'Vinz Clortho', phone: '555-1235', email: 'v.clortho@zuul.com' },
    { id: 12, name: 'Zuul', phone: '555-2346', email: 'zuul@zuul.com' },
    { id: 13, name: 'Stay Puft Marshmallow Man', phone: '555-3457', email: 'staypuft@marshmallow.com' },
    { id: 14, name: 'Librarian Ghost', phone: '555-4568', email: 'librarian@ghostbusters.com' },
    { id: 15, name: 'Vigo the Carpathian', phone: '555-5679', email: 'vigo@carpathia.com' },
    { id: 16, name: 'Mayor Lenny', phone: '555-6780', email: 'mayor.lenny@nyc.gov' },
    { id: 17, name: 'Dr. Janosz Poha', phone: '555-7891', email: 'j.poha@manhattanmuseum.com' },
  ]

  const contact = contacts.find(contact => contact.id === parseInt(contact_id))

  // console.log(contact)
  return (
    <Container>
      <Card className="shadow-sm">
        <Card.Header>
            Contact
        </Card.Header>
        <Table borderless style={{ marginLeft: "8px" }}>
          <tbody>
            {/* <tr>
              <th>#</th>
              <td>{contact.id}</td>
            </tr> */}
            <tr>
              <th>Name</th>
              <td>{contact.name}</td>
            </tr>
            <tr>
              <th>Phone</th>
              <td>{contact.phone}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                {/* <a href="#" onClick={handleEmailClick}>{contact.email}</a> */}
                <EmailModal recipient_name={contact.name} recipient_email_address={contact.email} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Container>
  )
}

export default Contacts