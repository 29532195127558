import React from 'react'
// import React, { useState } from 'react'
import {
  // Container,
  Form,
  // Button,
  // Row,
  // Col
  // InputGroup,
  // FormControl,
  // Spinner
} from "react-bootstrap"

export function FancyCharacterCounter(props) {
  let {
    counterlength,
    maxlength
  } = props

  // console.log("FancyCharacterCounter:::", counterlength, maxlength)

  if (counterlength > maxlength) {
    return (
      <Form.Text>
        <span className="text-danger">{counterlength}</span>/{maxlength}
      </Form.Text>
    )
  }
  return (
    <Form.Text>
      {counterlength}/{maxlength}
    </Form.Text>
  )
}
