import React from 'react'
// import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom"

// import {
//   createSelector
// } from '@reduxjs/toolkit'

// import {
// // Link,
//   Redirect
// } from 'react-router-dom'

// import DashboardNavbar from './DashboardNavbar/DashboardNavbar'
// import DashboardBody from './DashboardBody/DashboardBody'
import ContactWorkflowsList from './ContactWorkflowsList/ContactWorkflowsList'
import NewContactWorkflow from './NewContactWorkflow/NewContactWorkflow'
import EditContactWorkflow from './EditContactWorkflow/EditContactWorkflow'


const Contacts = () => {

  // const selectDummyResult = apiSlice.endpoints.getDummy.select()
  // // const emptyDummy = []
  // const selectAllDummy = createSelector(
  //   selectDummyResult,
  //   usersResult => usersResult.data
  // )

  // console.log("selectAllDummy", selectAllDummy())
  // console.log("selectDummyResult", selectDummyResult)

  return (
    <div id="contact-workflows-container" className="">
      {/* <h1>Dashboard Body</h1> */}
      <Routes>
        <Route path="/" element={<ContactWorkflowsList />} />
        <Route path="new/*" element={<NewContactWorkflow />} />
        <Route path=":contact_workflow_id/*" element={<EditContactWorkflow />} />

        {/* <Route path="contacts/*" element={<Contacts />} /> */}
        {/* <Route path="*" element={<NoMatch />} /> */}
      </Routes>
    </div>
  )
}

export default Contacts