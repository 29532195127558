import { createSlice } from '@reduxjs/toolkit'

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    username: 'test',
    user_id: null,
    email_address: null,
    organizations: []
  },
  reducers: {
    updateUserProfile: (state, action) => {
      state.username = action.payload.username
      state.user_id = action.payload.user_id
      state.email_address = action.payload.email_address
      state.organizations = action.payload.organizations
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateUserProfile } = userProfileSlice.actions

export default userProfileSlice.reducer