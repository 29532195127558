// https://github.com/parthprajapati32/reacthookform/blob/84f1e1ce0210044313d8cd5123c7dc798724d140/src/components/BootstrapForm/index.js
import * as React from "react"
import {
  Container,
  Form,
  Button,
  Row,
  Col
  // InputGroup,
  // FormControl
} from "react-bootstrap"

import { useForm } from "react-hook-form"

import { FancyCharacterCounter } from "../../FancyCharacterCounter/FancyCharacterCounter"

const EmailForm = (props) => {
  let {
    handleShowAIForm,
    handleSendEmail,
    handleEmailFormCancel,
    recipient_email_address,
    recipient_name,
    email_subject,
    email_body,
    email_send_button_text
  } = props

  const initState = {
    recipient_email_address: recipient_email_address,
    recipient_name: recipient_name,
    email_subject: email_subject || "",
    email_body: email_body || ""
  }

  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = React.useState(initState)

  const onSubmit = (values) => {
    console.log("Values:::", values)
    console.log("Values:::", JSON.stringify(values))
    handleSendEmail(values)
  }

  const onError = (error) => {
    console.log("ERROR:::", error)
  }

  const {
    register,
    handleSubmit,
    // getValues,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues
  })

  // const x = JSON.stringify(data);
  // const y = JSON.stringify(listShow);

  // React.useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     console.log(">>", value, name, type)
  //     // {1: '1', 2: '9'} '2' 'change'
  //   })

  //   return () => subscription.unsubscribe()
  // }, [watch])

  const current_email_subject_length = watch('email_subject').length
  const current_email_body_length = watch('email_body').length

  return (
    <Container className="my-1">

      {/* <a href="#" onClick={handleShowAIForm}>Show AI Form</a>

      <p>email: {recipient_email_address}</p>
      <p>email_body: {email_body}</p>

      <hr /> */}

      <Form onSubmit={handleSubmit(onSubmit, onError)}>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Recipient Name: <strong>{recipient_name}</strong></Form.Label>
          {/* <Form.Control
            type="email"
            placeholder="John Client"
            disabled
            {...register("recipient_name", { required: "Valid name" })}
          />
          {errors.recipient_name && (
            <Form.Text className="text-danger">
              {errors.recipient_name.message}
            </Form.Text>
          )} */}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Address: <strong>{recipient_email_address}</strong></Form.Label>
          {/* <Form.Control
            type="email"
            placeholder="client@domain.com"
            disabled
            {...register("recipient_email_address", { required: "Valid email required" })}
          />
          {errors.recipient_email_address && (
            <Form.Text className="text-danger">
              {errors.recipient_email_address.message}
            </Form.Text>
          )} */}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Subject:</Form.Label>
          <Form.Control
            type="text"
            placeholder="This email is regarding..."
            isInvalid={!!errors.email_subject}
            {...register("email_subject", {
              required: "Email subject required",
              minLength:{
                value: 2,
                message: "Email subject too short"
              },
              maxLength:{
                value: 255,
                message: "Email subject too long"
              },
            })}
          />
          <Row>
            <Col>
              {errors.email_subject && (
                <Form.Text className="text-danger">
                  {errors.email_subject.message}
                </Form.Text>
              )}
            </Col>
            <Col className="text-end">
              <FancyCharacterCounter counterlength={current_email_subject_length} maxlength={255} />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <div className="row">
            <div className="col">
              <Form.Label>Email Body:</Form.Label>
            </div>
            <div className="col text-end">
              <a
                href="#"
                onClick={handleShowAIForm}
                className="text-decoration-none"
                style={{ "fontSize": "0.8rem" }}
              >
                <span className="badge rounded-pill text-bg-warning" style={{ "fontSize": "0.6rem", "position":"relative", "top":-2, "left":-4 }}>New!</span>
                Auto-Generate Email with AI
              </a>
            </div>
          </div>
          <Form.Control
            as="textarea"
            rows="14"
            placeholder="Hello there client..."
            isInvalid={!!errors.email_body}
            {...register("email_body", {
              required: "Email body required",
              minLength:{
                value: 2,
                message: "Email body too short"
              },
              maxLength:{
                value: 1900,
                message: "Email body too long"
              },
            })}
          />
          <Row>
            <Col>
              {errors.email_body && (
                <Form.Text className="text-danger">
                  {errors.email_body.message}
                </Form.Text>
              )}
            </Col>
            <Col className="text-end">
              <FancyCharacterCounter counterlength={current_email_body_length} maxlength={1900} />
            </Col>
          </Row>
        </Form.Group>

        <div className="row">
          <div className="col">
            <Button variant="primary" type="submit">
              {email_send_button_text}
            </Button>
          </div>
          <div className="col text-end">
            <Button variant="light" className="ms-2" onClick={handleEmailFormCancel}>
              Close
            </Button>
          </div>
        </div>

      </Form>
    </Container>
  )
}

export default EmailForm
