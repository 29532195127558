import React from 'react'
// import React, { useEffect } from 'react'

import {
  Navigate
} from 'react-router-dom'

import { useSelector } from 'react-redux'

import Container from 'react-bootstrap/Container'

const Homepage = () => {
  // const org_id = "FAKE_ORG_ID"
  const organizations = useSelector(state => state.userProfile.organizations)

  // console.log("organizations", organizations)

  if(organizations.length < 1) {
    return (
      <Container className="mt-5 text-center">
        It looks like you aren't a member of any organizations yet. Please contact your administrator to get access.
      </Container>
    )
  } else if (organizations.length > 1) {
    return (
      <Container className="mt-5 text-center">
        <img src="https://www.lobsterlaw.com/images/logo1.png" alt="Logo" width="50" height="50" className="d-inline-block align-text-top" />
        <h1>Choose your organization</h1>
        { organizations.map((organization, index) => (
          <div key={index} className="mt-5">
            <a href={`/o/${organization.organization_id}`}>{organization.name}</a>
          </div>
        ))}
      </Container>
    )
  } else {
    return (
      // <h1>Homepage</h1>
      <Navigate to={ `/o/${organizations[0].organization_id}` } replace={true} />
    )
  }
}

export default Homepage