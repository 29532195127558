import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'

// import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const MattersBreadcrumb = (props) => {
  let { organization_id, organization_name } = props

  useEffect(() => {
    document.title = `LobsterLaw - Payments - ${organization_name}`
  })

  return (
    <Breadcrumb className="">
      <LinkContainer to={`/o/${organization_id}`}>
        <Breadcrumb.Item>{organization_name}</Breadcrumb.Item>
      </LinkContainer>
      <LinkContainer to={`/o/${organization_id}/payments`}>
        <Breadcrumb.Item active>Payments</Breadcrumb.Item>
      </LinkContainer>
    </Breadcrumb>
  )
}

export default MattersBreadcrumb