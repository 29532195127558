import React from 'react'
// import {
// // Link,
//   Redirect
// } from 'react-router-dom'
import { useParams } from 'react-router-dom'
// import { useSelector } from 'react-redux'

// import { generateAvatarUrl } from '../../../../lib/gravatar'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
// import Image from 'react-bootstrap/Image'

import Container from 'react-bootstrap/Container'

import { LinkContainer } from 'react-router-bootstrap'

const DashboardSubNavbar = () => {

  let { organization_id } = useParams()
  // const user_id = useSelector(state => state.userProfile.user_id)
  // const email_address = useSelector(state => state.userProfile.email_address)
  // const username = useSelector(state => state.userProfile.username)
  // console.log("email_address", email_address)

  // const profile_photo_url = generateAvatarUrl(email_address)

  // console.log("OK GOT organization_id", organization_id)

  // const queryClient = useQueryClient()
  // const cachedData = queryClient.getQueryData('getPokemonList')

  // console.log("OK GOT CACHED DATA", cachedData)

  const ContactsMenuTitle = (
    <span>
      {/* <i className="bi bi-file-earmark-person me-1"></i>Contacts */}
      {/* <i className="bi bi-emoji-heart-eyes-fill me-1"></i>Contacts */}
      <i className="bi bi-emoji-smile me-1"></i>Contacts
    </span>
  )

  // Icons: https://icons.getbootstrap.com/

  return (
    <Navbar bg="light" expand={true} className="border-bottom">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" activeKey="XXX">

            <LinkContainer to={`/o/${organization_id}/matters`}>
              {/* <Nav.Link className="ms-1 me-3 me-md-4"><i className="bi bi-folder2-open me-1"></i>Matters</Nav.Link> */}
              {/* <Nav.Link className="ms-1 me-3 me-md-4"><i className="bi bi-fingerprint me-1"></i>Matters</Nav.Link> */}
              <Nav.Link className="ms-1 me-3 me-md-4"><i className="bi bi-columns-gap me-1"></i>Matters</Nav.Link>
            </LinkContainer>

            <NavDropdown title={ContactsMenuTitle} className="me-3 me-md-4">

              <LinkContainer to={`/o/${organization_id}/contacts`}>
                <NavDropdown.Item>Contacts List</NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to={`/o/${organization_id}/contacts/contact_workflows`}>
                <NavDropdown.Item>Contact Workflows</NavDropdown.Item>
              </LinkContainer>

              {/*
              <LinkContainer to="/A2">
                <NavDropdown.Item>A2</NavDropdown.Item>
              </LinkContainer> */}

              {/* <NavDropdown.Divider />

              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}

            </NavDropdown>



            {/* <LinkContainer to={`/o/${organization_id}/contacts`}>
              <Nav.Link className="me-3 me-md-4"><i className="bi bi-file-earmark-person me-1"></i>Contacts</Nav.Link>
            </LinkContainer> */}

            <LinkContainer to={`/o/${organization_id}/payments`}>
              <Nav.Link className="me-3 me-md-4"><i className="bi bi-bank me-1"></i>Payments</Nav.Link>
            </LinkContainer>

            {/* <LinkContainer to={`/o/${organization_id}/workflows`}>
              <Nav.Link className="">Workflows</Nav.Link>
            </LinkContainer> */}

            {/*
            <LinkContainer to="/badlink">
              <Nav.Link>Bad Link</Nav.Link>
            </LinkContainer> */}

            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">

              <LinkContainer to="/A1">
                <NavDropdown.Item>A1</NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to="/A2">
                <NavDropdown.Item>A2</NavDropdown.Item>
              </LinkContainer>

              <NavDropdown.Divider />

              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>

            </NavDropdown> */}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default DashboardSubNavbar