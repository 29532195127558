import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom"

// import {
//   createSelector
// } from '@reduxjs/toolkit'

// import {
// // Link,
//   Redirect
// } from 'react-router-dom'

// import DashboardNavbar from './DashboardNavbar/DashboardNavbar'
// import DashboardBody from './DashboardBody/DashboardBody'
import Matters from './Matters/Matters'
import Contacts from './Contacts/Contacts'
import Payments from './Payments/Payments'
import DashboardHome from './DashboardHome/DashboardHome'
// import Workflows from './Workflows/Workflows'

function NoMatch() {

  useEffect(() => {
    document.title = "LobsterLaw - 404"
  })

  return (
    <div style={{ textAlign: 'center', paddingTop: '100px' }}>
      <h2>404</h2>
      <p>
        <a href="/">Go Home</a>
      </p>
    </div>
  )
}

const DashboardBody = () => {

  // const selectDummyResult = apiSlice.endpoints.getDummy.select()
  // // const emptyDummy = []
  // const selectAllDummy = createSelector(
  //   selectDummyResult,
  //   usersResult => usersResult.data
  // )

  // console.log("selectAllDummy", selectAllDummy())
  // console.log("selectDummyResult", selectDummyResult)

  return (
    <div id="dashboard-body-container">
      {/* <h1>Dashboard Body</h1> */}
      <Routes>
        <Route path="/" element={<DashboardHome />} />
        <Route path="matters/*" element={<Matters />} />
        <Route path="contacts/*" element={<Contacts />} />
        <Route path="payments/*" element={<Payments />} />
        {/* <Route path="workflows/*" element={<Workflows />} /> */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  )
}

export default DashboardBody