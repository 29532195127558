import React from 'react'
// import { LinkContainer } from 'react-router-bootstrap'
import Container from 'react-bootstrap/Container'


// import {
//   Navigate
// } from 'react-router-dom'
import { useParams, Routes, Route } from "react-router-dom"

import { useSelector } from 'react-redux'

import MattersBreadcrumb from './MattersBreadcrumb/MattersBreadcrumb'
import ContactsBreadcrumb from './ContactsBreadcrumb/ContactsBreadcrumb'
import ContactWorkflowsBreadcrumb from './ContactWorkflowsBreadcrumb/ContactWorkflowsBreadcrumb'
import PaymentsBreadcrumb from './PaymentsBreadcrumb/PaymentsBreadcrumb'
// import WorkflowsBreadcrumb from './WorkflowsBreadcrumb/WorkflowsBreadcrumb'
import DashboardHomeBreadcrumb from './DashboardHomeBreadcrumb/DashboardHomeBreadcrumb'

const DashboardBreadcrumb = () => {
  let { organization_id } = useParams()
  const organizations = useSelector(state => state.userProfile.organizations)
  const organization = organizations.find(organization => organization.organization_id === organization_id)

  return (
    <Container className="mt-2 mb-2">
      <Routes>
        <Route path="/" element={<DashboardHomeBreadcrumb organization_id={organization_id} organization_name={organization.name} />} />
        <Route path="matters/*" element={<MattersBreadcrumb organization_id={organization_id} organization_name={organization.name} />} />
        <Route path="contacts/contact_workflows/*" element={<ContactWorkflowsBreadcrumb organization_id={organization_id} organization_name={organization.name} />} />
        <Route path="contacts/*" element={<ContactsBreadcrumb organization_id={organization_id} organization_name={organization.name} />} />
        <Route path="payments/*" element={<PaymentsBreadcrumb organization_id={organization_id} organization_name={organization.name} />} />
        {/* <Route path="workflows/*" element={<WorkflowsBreadcrumb organization_id={organization_id} organization_name={organization.name} />} /> */}
        {/* <Route path="contacts/*" element={<Contacts />} /> */}
        {/* <Route path="*" element={<NoMatch />} /> */}
      </Routes>
    </Container>
  )
}

export default DashboardBreadcrumb