// https://github.com/parthprajapati32/reacthookform/blob/84f1e1ce0210044313d8cd5123c7dc798724d140/src/components/BootstrapForm/index.js
import React, { useState, useEffect } from "react"

import {
  Container,
  Form,
  Button,
  Row,
  Col,
  ProgressBar
  // InputGroup,
  // FormControl
} from "react-bootstrap"

import { useForm } from "react-hook-form"

import { useLazyGetAIDataQuery } from "../../../../slices/apiSlice"

import { FancyLoadingButton } from "../../FancyLoadingButton/FancyLoadingButton"
import { FancyCharacterCounter } from "../../FancyCharacterCounter/FancyCharacterCounter"

const EmailAIForm = (props) => {
  let {
    recipient_name,
    handleAIFormSubmit,
    handleAIFormCancel
  } = props

  const [timer_progress, setTimerProgress] = useState(1)
  // const time_to_subtrack = 1000

  const initState = {
    ai_email_prompt: ""
  }

  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = useState(initState)

  const [getAIData, {
    data: ai_data_result = [],
    isLoading: ai_data_is_loading,
    isFetching: ai_data_is_fetching,
    isSuccess: ai_data_is_success,
    isError: ai_data_is_error,
    error: ai_data_error,
  }] = useLazyGetAIDataQuery()

  // console.log("ai_data_result: ", ai_data_result)
  // console.log("ai_data_is_loading: ", ai_data_is_loading)
  // console.log("ai_data_is_fetching: ", ai_data_is_fetching)
  // console.log("ai_data_is_success: ", ai_data_is_success)
  // console.log("ai_data_is_error: ", ai_data_is_error)
  // console.log("ai_data_error: ", ai_data_error)

  const {
    register,
    handleSubmit,
    // getValues,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initialValues
  })

  const onSubmit = async (values) => {
    console.log("Values:::", values)
    // console.log("Values:::", JSON.stringify(values))
    // handleSendEmail(values)
    setTimerProgress(2)

    try {
      await getAIData({
        action: "GENERATE_AI_EMAIL",
        payload: {
          recipient_name: recipient_name,
          ai_email_prompt: values.ai_email_prompt
        }
      }).unwrap()
      setTimerProgress(100)
    } catch (err) {
      console.log("getAIData ERROR:::", err)
      setTimerProgress(0)
    }

  }

  const onError = (error) => {
    console.log("ERROR:::", error)
  }

  // const x = JSON.stringify(data);
  // const y = JSON.stringify(listShow);

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     console.log(">>", value, name, type)
  //     // {1: '1', 2: '9'} '2' 'change'
  //   })

  //   return () => subscription.unsubscribe()
  // }, [watch])

  useEffect(() => {
    if (!timer_progress) return
    const timerTimeout = setTimeout(() => {
      if (timer_progress === 100) {
        clearTimeout(timerTimeout)
      }
      if (timer_progress === 1) {
        return 1
      }
      setTimerProgress((timer_progress) => {
        return timer_progress + ((100 - timer_progress)/20)
      })
      // console.log(timer_progress)
    }, 500)
    return () => {
      clearInterval(timerTimeout)
    }
  }, [timer_progress])

  useEffect(() => {
    if (ai_data_is_success && ai_data_result) {
      console.log("useEffect:isSuccess", ai_data_is_success)
      console.log("useEffect:ai_data_result", ai_data_result)
      console.log("1")
      setTimeout(() => {
        console.log("2")
        console.log("ai_data_result", ai_data_result)
        handleAIFormSubmit({
          new_ai_generated_email_body: ai_data_result.ai_generated_email_body,
          new_ai_generated_email_subject: ai_data_result.ai_generated_email_subject,
        })
        console.log("3")
      }, 1000)
    }
  }, [ai_data_is_success, ai_data_result, handleAIFormSubmit])

  const current_ai_email_prompt_length = watch('ai_email_prompt').length

  let timer_text = "Submitting to AI engine..."
  if (timer_progress === 100) {
    timer_text = "Complete!"
  }


  return (
    <Container className="my-1">


      <Form onSubmit={handleSubmit(onSubmit, onError)}>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            {/* Describe the email you want to write */}
            <p>
              <span className="badge rounded-pill text-bg-warning" style={{ "fontSize": "0.6rem", "position":"relative", "top":-2, "left":-4 }}>New!</span>
              Describe the email you want to write and our AI engine will attempt to auto-generate it for you.</p>
            <p>
              For example: <i>Write an email to our client reminding them to pay their invoice.  Please include the following placeholder details: Invoice number, Invoice amount, Due date, and Payment methods</i>
            </p>
          </Form.Label>
          { ai_data_is_error && <div className="alert alert-danger" role="alert">ERROR: {ai_data_error.status}</div> }
          <Form.Control
            as="textarea"
            rows="5"
            placeholder={`Write an email to our client reminding them to pay their invoice.  Please include the following placeholder details: Invoice number, Invoice amount, Due date, and Payment methods.`}
            disabled={ai_data_is_loading || ai_data_is_fetching}
            isInvalid={!!errors.ai_email_prompt}
            {...register("ai_email_prompt", {
              required: "Required",
              minLength:{
                value: 2,
                message: "Too short"
              },
              maxLength:{
                value: 500,
                message: "Too long"
              },
            })}
          />

          <Row>
            <Col>
              {errors.ai_email_prompt && (
                <Form.Text className="text-danger">
                  {errors.ai_email_prompt.message}
                </Form.Text>
              )}
            </Col>
            <Col className="text-end">
              <FancyCharacterCounter counterlength={current_ai_email_prompt_length} maxlength={500} />
            </Col>
          </Row>

        </Form.Group>


        { timer_progress > 1 &&
          <div className="mb-3">
            <p className="text-center text-muted">{timer_text}</p>
            <ProgressBar animated now={timer_progress} variant="success" />
          </div>
        }

        { timer_progress < 2 &&
          <div className="row">
            <div className="col">
              <FancyLoadingButton variant="success" type="submit" buttontext={(<span><i className="bi bi-lightbulb me-1"></i>Submit to AI</span>)} buttonloadingtext={(<span><i className="bi bi-lightbulb-fill me-1"></i>Submitting to AI...</span>)} buttonisloading={ ai_data_is_loading || ai_data_is_fetching ? 1 : 0 }/>
            </div>
            <div className="col text-end">
              <Button variant="light" className="ms-2" onClick={handleAIFormCancel}>
                Cancel
              </Button>
            </div>
          </div>
        }

      </Form>
    </Container>
  )
}

export default EmailAIForm