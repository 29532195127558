import React from 'react'
// import {
// // Link,
//   Redirect
// } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { generateAvatarUrl } from '../../../../lib/gravatar'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'

import Container from 'react-bootstrap/Container'

import { LinkContainer } from 'react-router-bootstrap'

const DashboardNavbar = () => {

  let { organization_id } = useParams()
  // const user_id = useSelector(state => state.userProfile.user_id)
  const email_address = useSelector(state => state.userProfile.email_address)
  const username = useSelector(state => state.userProfile.username)
  // console.log("email_address", email_address)

  const profile_photo_url = generateAvatarUrl(email_address)

  // console.log("OK GOT organization_id", organization_id)

  // const queryClient = useQueryClient()
  // const cachedData = queryClient.getQueryData('getPokemonList')

  // console.log("OK GOT CACHED DATA", cachedData)

  const UserMenu = (
    <Image
      src={ profile_photo_url }
      alt="Profile image"
      roundedCircle
      style={{ width: '21px' }}
    />
  )

  return (
    <Navbar bg="light" expand={true} className="border-bottom">
      <Container>
        <LinkContainer to={`/o/${organization_id}`}>
          <Navbar.Brand href="/">
            <img src="https://www.lobsterlaw.com/images/logo1.png" alt="Logo" width="30" height="30" className="d-inline-block align-text-top me-2" />
            LobsterLaw
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" activeKey="XXX">

            {/* <LinkContainer to={`/o/${organization_id}/matters`}>
              <Nav.Link className="me-2 me-md-3">Matters</Nav.Link>
            </LinkContainer>

            <LinkContainer to={`/o/${organization_id}/contacts`}>
              <Nav.Link className="me-2 me-md-3">Contacts</Nav.Link>
            </LinkContainer>

            <LinkContainer to={`/o/${organization_id}/payments`}>
              <Nav.Link className="me-2 me-md-3">Payments</Nav.Link>
            </LinkContainer> */}

            {/* <LinkContainer to={`/o/${organization_id}/workflows`}>
              <Nav.Link className="">Workflows</Nav.Link>
            </LinkContainer> */}

            {/*
            <LinkContainer to="/badlink">
              <Nav.Link>Bad Link</Nav.Link>
            </LinkContainer> */}

            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">

              <LinkContainer to="/A1">
                <NavDropdown.Item>A1</NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to="/A2">
                <NavDropdown.Item>A2</NavDropdown.Item>
              </LinkContainer>

              <NavDropdown.Divider />

              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>

            </NavDropdown> */}

          </Nav>
          <Nav className="d-flex">
            <NavDropdown title={UserMenu} id="basic-nav-dropdown" align="end">

              <LinkContainer to="/profile">
                <NavDropdown.Item><i className="bi bi-person-circle me-2"></i>{username}</NavDropdown.Item>
              </LinkContainer>

              {/* <LinkContainer to="/A2">
                <NavDropdown.Item>A2</NavDropdown.Item>
              </LinkContainer> */}

              <NavDropdown.Divider />

              <NavDropdown.Item href="/">
                Logout
              </NavDropdown.Item>

            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default DashboardNavbar