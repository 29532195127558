// import React from 'react'
import React, {
  // useState
} from 'react'
import {
  // Container,
  Form,
  // Button,
  Row,
  Col
  // InputGroup,
  // FormControl
} from "react-bootstrap"

// import { LinkContainer } from 'react-router-bootstrap'
// import { Link } from "react-router-dom"

// import {
//   Navigate
// } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
// import Table from 'react-bootstrap/Table'

const ContactWorkflowFormItem = (props) => {
  let {
    workflow_step_number,
    form_errors,
    register,
    onRemoveContactWorkflowStep
  } = props

  const handleRemoveClick = (event) => {
    event.preventDefault()
    // console.log(1)
    onRemoveContactWorkflowStep(workflow_step_number)
    // console.log(2)
  }

  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Step #{workflow_step_number+1}</Form.Label>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Do This..."
            isInvalid={!!form_errors[`contact_workflow_step_${workflow_step_number}`]}
            {...register(`contact_workflow_step_${workflow_step_number}`, {
              required: "Workflow description required",
              minLength:{
                value: 2,
                message: "Too short"
              },
              maxLength:{
                value: 255,
                message: "Too long"
              },
            })}
          />
        </Col>
        <Col className="col-md-auto" style={{ padding: "0px 12px 0px 0px" }}>
          <a href="#" onClick={handleRemoveClick} className="d-block text-danger" style={{ paddingTop: "6px" }}><i className="bi bi-x-circle"></i></a>
        </Col>
      </Row>
      <Row>
        <Col>
          {form_errors[`contact_workflow_step_${workflow_step_number}`] && (
            <Form.Text className="text-danger">
              {form_errors[`contact_workflow_step_${workflow_step_number}`].message}
            </Form.Text>
          )}
        </Col>
        <Col className="text-end">
          {/* <FancyCharacterCounter counterlength={contact_workflow_step_1_length} maxlength={255} /> */}
        </Col>
      </Row>
    </Form.Group>
  )
}

export default ContactWorkflowFormItem