// import React from 'react'
import React, { useState } from 'react'

import {
  Container,
  // Form,
  // Button,
  Row,
  Col
  // InputGroup,
  // FormControl
} from "react-bootstrap"

import {
  Navigate,
  useParams
} from 'react-router-dom'


import {
  Card
} from 'react-bootstrap'

import ContactWorkflowForm from '../ContactWorkflowForm/ContactWorkflowForm'

const initial_contact_workflow_obj = {
  workflow_steps: [
    {
      // hours_til_due: '',
      step_description: '',
    },
    // {
    //   hours_til_due: '1',
    //   step_description: 'Step 1 description',
    // },
    // {
    //   hours_til_due: '48',
    //   step_description: 'Step 2 description',
    // },
    // {
    //   hours_til_due: '90',
    //   step_description: 'Step 3 description',
    // },
    // {
    //   hours_til_due: '90',
    //   step_description: 'Step 4 description',
    // },
    // {
    //   hours_til_due: '90',
    //   step_description: 'Step 5 description',
    // },
  ]
}



const NewContactWorkflow = () => {
  let { organization_id } = useParams()
  // console.log("useParams()", useParams())

  const [show_dummy_form, setShowDummyForm] = useState(false)
  const [navigate_away, setNavigateAway] = useState(false)
  const [contact_workflow_obj, setContactWorkflowOBJ] = useState(initial_contact_workflow_obj)

  const handleSaveContactWorkflow = (new_workflow_steps) => {
    console.log('handleSaveContactWorkflow', new_workflow_steps)
  }

  const handleAddContactWorkflowStep = (current_workflow_steps) => {

    current_workflow_steps.workflow_steps.push({
      // hours_til_due: '',
      step_description: '',
    })

    setContactWorkflowOBJ(current_workflow_steps)
  }

  const handleRemoveContactWorkflowStep = (current_workflow_steps, step_to_remove) => {

    setShowDummyForm(true)

    current_workflow_steps.workflow_steps.splice(step_to_remove, 1)

    if (current_workflow_steps.workflow_steps.length === 0) {
      current_workflow_steps = initial_contact_workflow_obj
    }

    setContactWorkflowOBJ(current_workflow_steps)

    // This is a stupid hack to get react-hook-form to re-render the form.  Make this better.
    setTimeout(() => {
      setShowDummyForm(false)
    }, "10")

  }

  const handleCancel = () => {
    // console.log('handleCancel')
    setNavigateAway(`/o/${organization_id}/contacts/contact_workflows`)
  }

  const handleShowAIForm = () => {
    console.log('handleShowAIForm')
  }

  if (navigate_away) {
    return (
      <Navigate to={navigate_away} />
    )
  }

  if (show_dummy_form) {
    return (
      <Container>
        <h1>&nbsp;</h1>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col md={12} lg={8} xl={9}>
          <Card className="shadow-sm">
            <Card.Header>

              <div className="row">
                <div className="col">
                  New Contact Workflow
                </div>
                <div className="col text-end">
                  <a
                    href="#"
                    onClick={handleShowAIForm}
                    className="text-decoration-none"
                    style={{ "fontSize": "0.8rem" }}
                  >
                    <span className="badge rounded-pill text-bg-warning" style={{ "fontSize": "0.6rem", "position":"relative", "top":-2, "left":-4 }}>New!</span>
                    Auto-Generate Workflow with AI
                  </a>
                </div>
              </div>

            </Card.Header>
            <Card.Body>
              <ContactWorkflowForm
                handleSaveContactWorkflow={handleSaveContactWorkflow}
                handleAddContactWorkflowStep={handleAddContactWorkflowStep}
                handleRemoveContactWorkflowStep={handleRemoveContactWorkflowStep}
                handleCancel={handleCancel}
                initial_contact_workflow={contact_workflow_obj}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} xl={3} className="d-none d-lg-block">
          <Card className="shadow-sm">
            {/* <Card.Header>
              xxx
            </Card.Header> */}
            <Card.Body>

              <Row>
                <Col className="col-md-auto" style={{ padding: "0px 0px 0px 12px" }}>
                  <i className="bi bi-lightbulb"></i>
                </Col>
                <Col>
                  <p>Contact workflows are used to track each step of a client's journey with your law firm.</p>
                  <p style={{ padding: "0px 0px 0px 0px", margin: "0px" }}>You can create as many workflows as you need, and assign them to clients as needed.</p>
                </Col>
              </Row>


            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default NewContactWorkflow