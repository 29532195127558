import React from 'react'
import {
  Container,
  // Form,
  Button,
  Row,
  Col
  // InputGroup,
  // FormControl
} from "react-bootstrap"

// import { LinkContainer } from 'react-router-bootstrap'
import { Link, useParams } from 'react-router-dom'


// import {
//   Navigate
// } from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'

import {
  Card
} from 'react-bootstrap'

import { LinkContainer } from 'react-router-bootstrap'

const ContactWorkflowsList = () => {
  let { organization_id } = useParams()
  const contacts = [
    { id: 1, client: 'Divorce Client Onboarding Flow' },
    { id: 2, client: 'Insurance Client Onboarding Flow' },
    { id: 3, client: 'Hit-And-Run Client Onboarding Flow' },
  ]
  return (
    <Container>
      <Card className="shadow-sm">
        <Card.Header>
          {/* <button type="button" className="btn btn-success btn-sm">Success</button> */}

          <Row>
            <Col>
              Contact Workflows
            </Col>
            <Col className="text-end">
              <LinkContainer to={`/o/${organization_id}/contacts/contact_workflows/new`}>
                <Button variant="success" className="ms-2 btn-sm card-header-button">
                  <i className="bi bi-plus-lg me-1"></i>Create New Workflow
                </Button>
              </LinkContainer>
            </Col>
          </Row>

        </Card.Header>
        <div className="table-responsive-sm">
          <Table borderless hover style={{ marginLeft: "8px" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>
                    {contact.id}
                  </td>
                  <td><Link to={`./${contact.id}`}>{contact.client}</Link></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    </Container>
  )
}

export default ContactWorkflowsList