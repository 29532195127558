import React from 'react'
// import { Routes, Route } from "react-router-dom"
import { Link } from "react-router-dom"

// import { apiSlice } from "../../../../api/apiSlice"

import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
// import ListGroup from 'react-bootstrap/ListGroup'

import { DashboardModule } from '../../../../shared/DashboardModule/DashboardModule'
import { RevenueChartModule } from './RevenueChartModule/RevenueChartModule'

function renderDashboardModules() {
  return (
    <>
      <DashboardModule>
        <Card.Header>
          <i className="bi bi-emoji-smile me-1"></i>New Clients
        </Card.Header>
        {/* <ul className="list-group list-group-flush">
            <li className="list-group-item">An item</li>
            <li className="list-group-item">A second item</li>
            <li className="list-group-item">A third item</li>
          </ul> */}
        {/* <ListGroup variant="flush">
            <ListGroup.Item><Link to={`./contacts/5`}>Dana Barrett</Link></ListGroup.Item>
            <ListGroup.Item><Link to={`./contacts/6`}>Louis Tully</Link></ListGroup.Item>
          </ListGroup> */}
        <Table borderless hover>
          {/* <thead style={{ borderBottom: "1px solid #EEEEEE" }}>
              <tr>
                <th>Name</th>
                <th>Age</th>
              </tr>
            </thead> */}
          <tbody>
            <tr>
              <td style={{ paddingLeft: "13px" }}><Link to={`./contacts/5`}>Dana Barrett</Link></td>
              <td className="text-muted">5 Min Ago</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "13px" }}><Link to={`./contacts/6`}>Louis Tully</Link></td>
              <td className="text-muted">2 Days Ago</td>
            </tr>
          </tbody>
        </Table>
        {/* <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
          </Card.Body> */}
        {/* <Card.Footer>
            2 days ago
          </Card.Footer> */}
      </DashboardModule>

      <DashboardModule>
        <Card.Header>
            Recent Matters/Cases
        </Card.Header>
        {/* <ListGroup variant="flush">
            <ListGroup.Item><Link to={`./contacts/5`}>Ghost Containment Breach</Link></ListGroup.Item>
            <ListGroup.Item><Link to={`./contacts/6`}>Ectoplasmic Residue Damage</Link></ListGroup.Item>
          </ListGroup> */}
        <Table borderless hover>
          {/* <thead style={{ borderBottom: "1px solid #EEEEEE" }}>
              <tr>
                <th>Case</th>
                <th>Status</th>
              </tr>
            </thead> */}
          <tbody>
            <tr>
              <td style={{ paddingLeft: "13px" }}><Link to={`./matters`}>Spook Central</Link></td>
              <td className="text-muted">Open</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "13px" }}><Link to={`./matters`}>Keymaster</Link></td>
              <td className="text-muted">Closed</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "13px" }}><Link to={`./matters`}>Spook Central</Link></td>
              <td className="text-muted">Open</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "13px" }}><Link to={`./matters`}>Keymaster</Link></td>
              <td className="text-muted">Closed</td>
            </tr>
          </tbody>
        </Table>
      </DashboardModule>
    </>
  )
}

function DashboardHome() {

  return (
    <Container className="">
      <RevenueChartModule />
      <Row className="row-cols-1 row-cols-md-3 g-4 mt-1">
        {renderDashboardModules()}
        {renderDashboardModules()}
        {renderDashboardModules()}
        {renderDashboardModules()}
        {renderDashboardModules()}
      </Row>
      {/* Add more Rows and Cols as needed for other categories... */}
    </Container>
  )
}


export default DashboardHome