// import React from 'react'
import React, { useState } from 'react'

import {
  Container,
  // Form,
  // Button,
  Row,
  Col
  // InputGroup,
  // FormControl
} from "react-bootstrap"

import {
  Navigate,
  useParams
} from 'react-router-dom'


import {
  Card
} from 'react-bootstrap'

import ContactWorkflowForm from '../ContactWorkflowForm/ContactWorkflowForm'

const empty_contact_workflow_obj = {
  workflow_steps: [
    {
      // hours_til_due: '',
      step_description: '',
    }
  ]
}

const initial_contact_workflow_obj = {
  workflow_steps: [
    {
      // hours_til_due: '1',
      step_description: 'Send initial welcome email',
    },
    {
      // hours_til_due: '48',
      step_description: 'Schedule initial phone call',
    },
    {
      // hours_til_due: '90',
      step_description: 'Add client to appropriate email list',
    },
    {
      // hours_til_due: '90',
      step_description: 'Set up retainer payment',
    },
    {
      // hours_til_due: '90',
      step_description: 'Send client intake form',
    },
  ]
}



const EditContactWorkflow = () => {
  let { organization_id } = useParams()
  // console.log("useParams()", useParams())

  const [show_dummy_form, setShowDummyForm] = useState(false)
  const [navigate_away, setNavigateAway] = useState(false)
  const [contact_workflow_obj, setContactWorkflowOBJ] = useState(initial_contact_workflow_obj)

  const handleSaveContactWorkflow = (new_workflow_steps) => {
    console.log('handleSaveContactWorkflow', new_workflow_steps)
  }

  const handleAddContactWorkflowStep = (current_workflow_steps) => {
    // console.log('1 handleAddContactWorkflowStep', current_workflow_steps)

    current_workflow_steps.workflow_steps.push({
      // hours_til_due: '',
      step_description: '',
    })

    // console.log('2 handleAddContactWorkflowStep', current_workflow_steps)

    setContactWorkflowOBJ(current_workflow_steps)
  }

  const handleRemoveContactWorkflowStep = (current_workflow_steps, step_to_remove) => {

    setShowDummyForm(true)

    current_workflow_steps.workflow_steps.splice(step_to_remove, 1)

    if (current_workflow_steps.workflow_steps.length === 0) {
      current_workflow_steps = empty_contact_workflow_obj
    }

    setContactWorkflowOBJ(current_workflow_steps)

    // This is a stupid hack to get react-hook-form to re-render the form.  Make this better.
    setTimeout(() => {
      setShowDummyForm(false)
    }, "10")

  }

  const handleCancel = () => {
    setNavigateAway(`/o/${organization_id}/contacts/contact_workflows`)
  }

  if (navigate_away) {
    return (
      <Navigate to={navigate_away} />
    )
  }

  if (show_dummy_form) {
    return (
      <Container>
        <h1>&nbsp;</h1>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col md={12} lg={8} xl={9}>
          <Card className="shadow-sm">
            <Card.Header>
              Edit Contact Workflow
            </Card.Header>
            <Card.Body>
              <ContactWorkflowForm
                handleSaveContactWorkflow={handleSaveContactWorkflow}
                handleAddContactWorkflowStep={handleAddContactWorkflowStep}
                handleRemoveContactWorkflowStep={handleRemoveContactWorkflowStep}
                handleCancel={handleCancel}
                initial_contact_workflow={contact_workflow_obj}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} xl={3} className="d-none d-lg-block">
          <Card className="shadow-sm">
            {/* <Card.Header>
              xxx
            </Card.Header> */}
            <Card.Body>

              <Row>
                <Col className="col-md-auto" style={{ padding: "0px 0px 0px 12px" }}>
                  <i className="bi bi-lightbulb"></i>
                </Col>
                <Col>
                  <p>Contact workflows are used to track each step of a client's journey with your law firm.</p>
                  <p style={{ padding: "0px 0px 0px 0px", margin: "0px" }}>You can create as many workflows as you need, and assign them to clients as needed.</p>
                </Col>
              </Row>


            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default EditContactWorkflow